<template>
  <div class="">
    <transition name="fade">
      <div v-if="primaryImageUrl">
        <div class="hover:shadow-xl group">
          <NuxtLink :to="`/vehicle-view/${vehicle.slug}`">
            <div class="relative md:h-48 w-full bg-mv-light-gray rounded-t-md">
              <div
                class="text-gray-300 absolute top-20 z-50 w-full text-center"
                v-if="imageLoad"
              >
                <i class="fa-solid fa-spinner-third fa-spin"></i>
              </div>
              <!-- Images -->
              <div v-if="primaryImageUrl">
                 <carousel ref="imageCarousel" :items-to-show="1">
                  <slide v-for="image in images" :key="image">
                    <NuxtImg
                    v-if="!placeholderImage"
                    provider="imagekit"
                    width="523"
                    height="392"
                    sizes='xs:100px sm:300px md:700px'
                    class="w-full rounded-t-md object-cover h-full"
                    :src="'uploads/vehicle_image/image/' + image.id + '/' + image.image"
                    @load="imageLoad = false"
                    :alt="'Image of '+ vehicle.manufacturer_name +' '+vehicle.model_name+' '+vehicle.body_type_name+' '+vehicle.equipment_manufacturer_name+' '+vehicle.equipment_model_name +' for sale at MV Commercial, the UKs leading supplier of new and used trucks, trailers and vans. ('+ vehicle.registration_number + ' '+ image.id + ')'"

                  />
                  </slide>

                </carousel>
              </div>

               <NuxtImg provider="netlify"
                class="rounded-t-md object-cover h-full w-full"
                v-if="placeholderImage"
                :src="primaryImageUrl"
              />
              <div
                v-if="!placeholderImage && images.length > 1 && !marketing"
                style="top: 50%"
                class="absolute right-5 rounded-full lg:w-5 lg:h-5 h-7 w-7 lg:text-xs text-xl bg-white text-center cursor-pointer  items-center flex justify-center"
                @click.prevent="scrollImage('r')"
              >
                <div><i class="fas fa-chevron-right"></i></div>
              </div>

              <div
                v-if="!placeholderImage && images.length > 1 && !marketing"
                style="top: 50%"
                class="absolute left-5 rounded-full lg:w-5 lg:h-5 h-7 w-7 lg:text-xs text-xl bg-white text-center cursor-pointer  items-center flex justify-center"
                @click.prevent="scrollImage('l')"
              >
                <div><i class="fas fa-chevron-left"></i></div>
              </div>

              <div
                v-if="!placeholderImage && imageCarousel && !marketing"
                style="bottom: 5px"
                class="absolute hidden md:flex right-1 w-8 h-6 text-xs bg-mv-gray bg-opacity-70 text-white text-center cursor-pointer p-1 items-center justify-center"
              >
                {{ currentSlide }}/{{ imageCarousel.data.slidesCount  }}
              </div>
              <div v-if="!marketing" class="absolute top-2 right-2 flex">
                <div
                  class="text-xxs text-white bg-mv-black rounded-lg flex px-3 py-0.5 align-middle cursor-pointer"
                  @click.prevent="addToShortlist(vehicle)"
                >
                  <div class="mt-0.5">Shortlist</div>
                  <i
                    class="mt-1 ml-2 text rounded-full text-center  bg-mv-black shadow-md content-center text-xxs justify-center align-middle cursor-pointer"
                    :class="
                      vehicleIsInShortlist(vehicle.id)
                        ? 'fa fa-star text-mv-red'
                        : 'fa fa-star-o '
                    "
                  ></i>
                </div>
              </div>

              <div
                v-if="vehicle.flag_id == 1"
                class="bg-mv-red text-white text-center rounded-none text-xxs p-1 absolute top-0 left-1"
              >
                Newly Listed
              </div>

              <div
                v-if="vehicle.flag_id == 2"
                class="bg-mv-red text-white text-center rounded-none text-xxs p-1 absolute md:-bottom-7 bottom-0 left-0.5 "
              >
                For lease, hire & rent to buy
              </div>
            </div>

            <!-- Bottom Box -->
            <div
              v-if="images.length == 0"
              class="bg-mv-black text-white text-center rounded-none text-xxs p-1 top-0 right-0"
            >
              {{ vehicle.body_type_name }}
            </div>
            <div
              v-else
              class="bg-white text-white text-center rounded-none text-xxs p-1 top-0 right-0 border-x border-x-mv-light-gray"
            >
              {{ vehicle.body_type_name }}
            </div>

            <div
              class="w-full relative bg-white p-5 rounded-b-md border-x border-b border-mv-light-gray"
            >
              <!-- Make, model and equipment -->
              <div class="h-16">
                <h2 class="font-bold tracking-wider line-clamp-1">
                  {{ vehicle.manufacturer_name }} {{ vehicle.model_name }}
                  <span
                    class="text-gray-400"
                    v-if="vehicle.body_type_name != '-'"
                    >{{ vehicle.body_type_name }}
                  </span>
                </h2>
                <h3 class="font-thin text-sm">
                  <span v-if="vehicle.equipment_manufacturer_name != '-'">{{
                    vehicle.equipment_manufacturer_name 
                  }} </span>
                  <span class="pl-2 italic" v-if="vehicle.equipment_model_name != '-'"
                    >{{ vehicle.equipment_model_name }}
                  </span>
                </h3>
              </div>

              <!-- BlackPills -->
              <div class="flex flex-wrap h-12 gap-y-2 text-xs overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
                <div
                  v-if="vehicle.body_type_name && vehicle.body_type_name != '-'"
                  class="bg-mv-black rounded-md text-white text-[10px] py-0.5 px-1 mr-2 shrink-0 h-5"
                >
                  {{ vehicle.body_type_name }}
                </div>

                <div
                  v-if="vehicle.weight_name && vehicle.weight_name != '-'"
                  class="bg-mv-black rounded-md text-white text-[10px] py-0.5 px-1 mr-2 shrink-0 h-5"
                >
                  {{ vehicle.weight_name }}
                </div>

                <div
                  v-if="vehicle.axle_name && vehicle.axle_name != '-'"
                  class="bg-mv-black rounded-md text-white text-[10px] py-0.5 px-1 mr-2 shrink-0 h-5"
                >
                  {{ vehicle.axle_name }}
                </div>

                <div
                  v-if="vehicle.cab_type_name && vehicle.cab_type_name != '-'"
                  class="bg-mv-black rounded-md text-white text-[10px] py-0.5 px-1 mr-2 shrink-0 h-5"
                >
                  {{ vehicle.cab_type_name }}
                </div>

                <div
                  v-if="vehicle.euro_class && vehicle.euro_class != '-'"
                  class="bg-mv-black rounded-md text-white text-[10px] py-0.5 px-1 mr-2 shrink-0 h-5"
                >
                  {{ vehicle.euro_class }}
                </div>
                    <div
                  v-if="vehicle.euro_class && vehicle.euro_class == 'Euro 6'"
                  class="bg-mv-black rounded-md text-white text-[10px] py-0.5 px-1 mr-2 shrink-0 h-5"
                >
                  ULEZ Compliant
                </div>
              </div>

              <div
                class="grid grid-cols-2 text-mv-gray gap-x-3 text-sm pb-5 mb-1 mt-2 h-20"
              >
                <div class="my-3">
                <div v-if="vehicle.year_of_registration">
                  <i
                   
                    class="fa-solid fa-calendar-days fa-lg text-mv-border-gray mr-2 ml-1"
                  ></i
                  >
                  {{ vehicle.year_of_registration }}
                  
                  </div>
                  <div v-else><i class="fas fa-star fa-lg text-mv-border-gray mr-2 ml-0.5"></i>New</div>
                </div>
                

                <div class="my-3" v-if="vehicle.body_type_name != 'Trailers'">
                  <i
                    v-if="vehicle.formatted_mileage != 'Trailers' && vehicle.formatted_mileage" class="fa-regular fa-lg fa-gauge-low text-mv-border-gray mr-3 ml-1"></i
                  >{{ vehicle.formatted_mileage }}
                </div>
                <div v-else></div>

                <div
                  class="w-32 flex"
                  v-if="vehicle.body_type_name != 'Trailers'"
                >
                   <img provider="netlify"
                    v-if="vehicle.gearbox_name"
                    src="/assets/img/brand/gear-icon.png"
                    class="w-4 h-4 ml-0.5 mr-2"
                    style="width:19px; height:19px"
                    alt="gearbox icon"
                  /><span class="line-clamp-1">{{ vehicle.gearbox_name }}</span>
                </div>

                <div class="flex">
                   <img provider="netlify"
                    v-if="vehicle.formatted_mot_due"
                    src="/assets/img/brand/mot-icon.png"
                    class="w-4 h-4 mr-2"
                    style="width:19px; height:19px"
                    alt="mot icon"

                  />{{ vehicle.formatted_mot_due }}
                </div>
              </div>

              <div
                class="border-t-2 text-right border-gray-400 h-5 group-hover:border-mv-red py-1 group-hover:text-black text-gray-300"
              >
                <strong
                  class="font-bold w-full font-UKNumberPlate border border-gray-400 px-2 py-1"
                  >{{ vehicle.registration_number }}</strong
                >
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </transition>
    <div v-if="!primaryImageUrl && !marketing">
      <!-- Skeleton -->
      <div
        class="lg:flex  md:h-48 h-72 bg-gray-200 border-gray-400 border animate-pulse rounded-t-md hidden w-[273px]"
      ></div>
      <div
        class="w-full md:h-48 h-72 bg-gray-200 border-gray-400 border animate-pulse rounded-t-md  lg:hidden"
      ></div>


      <div
        class="h-52 hidden bg-white lg:flex flex-col p-5 rounded-b-md w-[273px]"
      >
        <div class="bg-gray-100 h-5 w-full animate-pulse"></div>
        <div class="bg-gray-100 h-5 w-2/3 animate-pulse mt-2"></div>

        <div class="grid grid-cols-4 gap-5">
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
        </div>
        <div class="grid grid-cols-2 gap-5 mt-5">
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
        </div>
      </div>

      




       <div
        class="h-52  bg-white flex lg:hidden flex-col p-5 rounded-b-md"
        style="width: 100vw"
      >
        <div class="bg-gray-100 h-5 w-full animate-pulse"></div>
        <div class="bg-gray-100 h-5 w-2/3 animate-pulse mt-2"></div>
        <div class="grid grid-cols-4 gap-5">
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
        </div>
        <div class="grid grid-cols-2 gap-5 mt-5">
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
          <div class="bg-gray-100 h-5 w-full animate-pulse mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useShortlistStore } from "~/store/shortlist";
import { storeToRefs } from "pinia";


import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

const shortlistStore = useShortlistStore();
const { shortlist } = storeToRefs(shortlistStore);
const {
  addVehicleToShortlist,
  removeVehicle,
  clearShortlist,
  vehicleIsInShortlist,
} = useShortlistStore();

const imageCarousel = ref(null);

const props = defineProps({
  vehicle: Object,
  marketing: Boolean,
});
const primaryImageUrl = ref();
// const currentImage = ref(1);
const images = ref();
const imageCount = ref(0);
const placeholderImage = ref(false);
const imageLoad = ref(true);

onMounted(() => {
  if (props.vehicle) {
    let primaryImage = props.vehicle.images.filter((el) => {
      return el.position == 1;
    });

    //what if there is none marked as primary? Select the first one!
    if(primaryImage.length == 0){
      primaryImage[0] = props.vehicle.images[0];
    }

    if (props.vehicle.images) {
      imageCount.value = props.vehicle.images.length;
    }

    if (primaryImage[0]) {
      primaryImageUrl.value =
        "uploads/vehicle_image/image/" +
        primaryImage[0].id +
        "/" + 
        primaryImage[0].image;
    } else {
      primaryImageUrl.value = "/assets/img/brand/image-placeholder.webp";
      placeholderImage.value = true;
    }

    images.value = props.vehicle.images.filter((el) => {
      return el.category == "Website Images";
    });

    if(window.innerWidth < 768) {
          images.value = images.value.slice(0, 5);
    }

  }
});

function scrollImage(direction) {
  // imageLoad.value = true;

  if (direction == "r") {
      imageCarousel.value.next();
  } else {
      imageCarousel.value.prev();
  }
}

function addToShortlist(vehicle) {
  addVehicleToShortlist(vehicle.id, vehicle);
}

const currentSlide = computed(() => {
  if (imageCarousel.value) {
    return imageCarousel.value.data.currentSlide.value + 1;
  }
});



</script>

<style>
.fade-enter-active,
.fade-leave-active {
  opacity: 0;
  transition: opacity 1s;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.slide-fade-enter-active {
  transition: all 0.25s ease;
}

.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: scale(0.95);
  opacity: 0;
}
</style>
